export class FormatUtil {

    static formatNdc(incoming: string): string {
        if (incoming == null ) {
            return incoming;
        }
        while (incoming.toString().length < 11) {
            incoming = '0' + incoming;
        }
        return incoming.toString().substring(0, 5) + '-' +
            incoming.toString().substring(5, 9) + '-' +
            incoming.toString().substring(9, 11);
    }
}
