import {Component} from '@angular/core';

@Component({
    selector: 'my-alt-ordering-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterComponent {

    currentYear: number;

    constructor() {
        this.currentYear = new Date().getFullYear();
    }
}
