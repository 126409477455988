import {Component, ViewChild, ElementRef, Renderer, AfterViewInit, OnDestroy} from '@angular/core';
import {Product} from '../model/product';
import {ProductsService} from '../services/products.service';
import {AppErrorConstants} from '../constants/apperror.constants';
import {ComponentCommunicationService} from '../services/componentcommunication.service';
import {Constants} from '../constants/app.constants';
import {Subscription} from 'rxjs';
import {FormatUtil} from '../util/foramtutil';

@Component({
    selector: 'my-alt-ordering-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})

export class SearchComponent implements AfterViewInit, OnDestroy {

    @ViewChild('productSearch') productSearch: ElementRef;
    @ViewChild('poNumberInput') poNumberInput: ElementRef;
    product: string;
    quantity: number;
    poNumber: string;
    errorMessage: string;
    subscription: Subscription;
    publixDisabled:boolean = false;//OE-7439 || Publix || Emergency Ordering - Autogenerate Purchase Order Number

    constructor(private renderer: Renderer,
                private productsService: ProductsService,
                private communicationService: ComponentCommunicationService) {
        this.focusOnProductSearchBox();
        this.focusOnPONumberBox();
        this.clearInputsCommand();
        this.capturePOFromImport();
        //OE-7439 || Publix || Emergency Ordering - Autogenerate Purchase Order Number - start
        let building = localStorage.getItem('publix-building');
        if(building != null)
            {
                this.publixDisabled = true; 
                console.log("Public disabled Flag "+this.publixDisabled);
            }
        //OE-7439 || Publix || Emergency Ordering - Autogenerate Purchase Order Number - end
    }

    ngAfterViewInit() {
        this.renderer.invokeElementMethod(this.productSearch.nativeElement,
            'focus');
    }

    focusPOInputControl() {
        this.renderer.invokeElementMethod(this.poNumberInput.nativeElement,
            'focus');
    }

    focusOnProductSearchBox() {
        this.subscription = this.communicationService.focusBackToSearch$.subscribe(
            focus => {
                if (focus) {
                    this.ngAfterViewInit();
                }
            });
    }

    focusOnPONumberBox() {
        this.subscription = this.communicationService.focusBackToPONumber$.subscribe(
            focus => {
                if (focus) {
                    this.focusPOInputControl();
                }
            });
    }

    clearInputsCommand() {
        this.subscription = this.communicationService.clearInputs$.subscribe(
            clear => {
                if (clear) {
                    this.clearInputs();
                }
            });
    }

    capturePOFromImport() {
        this.subscription = this.communicationService.poNumberField$.subscribe(
            poNumber => {
                this.poNumber = poNumber;
            });
    }

    onKeyPressProduct(event) {
        this.setErrorMessage('');
        let keyCode = window.event ? event.keyCode : event.which;
        let meta = event.metaKey;
        let isAllowedCopyPasteKey = Constants.ALLOWED_CHAR_CODES_FOR_COPY_PASTE.filter( code => code === keyCode).length === 1 && meta;
        let isArrowKey = Constants.MOVE_RIGHT_LEFT.filter( code => code === keyCode).length === 1;
        if (keyCode && (keyCode >= 48 && keyCode <= 57) || keyCode === 45 || keyCode === 13 || isAllowedCopyPasteKey || isArrowKey) {
            if (keyCode === 13) {
                this.validateInputAndSearch(this.product);
            }
            return true;
        }
        return false;
    }

    onKeyPressQty(event) {
        let keyCode = window.event ? event.keyCode : event.which;
        let meta = event.metaKey;
        let isAllowedCopyPasteKey = Constants.ALLOWED_CHAR_CODES_FOR_COPY_PASTE.filter( code => code === keyCode).length === 1 && meta;
        let isArrowKey = Constants.MOVE_RIGHT_LEFT.filter( code => code === keyCode).length === 1;
        if (keyCode && (keyCode >= 48 && keyCode <= 57) || keyCode === 13 || isAllowedCopyPasteKey || isArrowKey) {
            if (keyCode === 13) {
                this.validateInputAndSearch(this.product);
            }
            return true;
        }
        return false;
    }

    onKeyPressPONumber(event) {
        let keyCode = window.event ? event.keyCode : event.which;
        let meta = event.metaKey;
        let isAllowedSpecialChar = Constants.ALLOWED_SPECIAL_CHAR_CODES_PO_NUMBER.filter( code => code === keyCode).length === 1;
        let isAllowedCopyPasteKey = Constants.ALLOWED_CHAR_CODES_FOR_COPY_PASTE.filter( code => code === keyCode).length === 1 && meta;
        let isArrowKey = Constants.MOVE_RIGHT_LEFT.filter( code => code === keyCode).length === 1;
        let poLength = this.poNumber ? this.poNumber.length : 0;
        let isNumeric = keyCode >= 48 && keyCode <= 57;
        let isAlpha = (keyCode >= 65 && keyCode <= 90) || (keyCode >= 97 && keyCode <= 122);
        if (keyCode && ((isAllowedSpecialChar || isNumeric || isAlpha) && poLength < 25) || keyCode === 13
            || isAllowedCopyPasteKey || isArrowKey) {
            if (keyCode === 13) {
                this.validateInputAndSearch(this.product);
            }
            return true;
        }
        return false;
    }

    onBlurPONumber() {
        this.communicationService.currentPONumber(this.poNumber);
    }

    validateInputAndSearch(product: string) {
        this.setErrorMessage('');
        if (!product || !Number(product.trim().replace(/-/g, '')) || product.trim().replace(/-/g, '').length < 7) {
            if (product && !Number(product.trim().replace(/-/g, ''))) {
                this.product = null;
            }
            this.setErrorMessage(AppErrorConstants.MIN_LENGTH_PRODUCT_SEARCH.value);
        } else {
            let userInputForProduct = product.trim().replace(/-/g, '');
            if (userInputForProduct.length === 7) {
                this.searchForProductByCIN(userInputForProduct, this.getQuantity());
            } else {
                this.searchForProductByNdcOrUpc(userInputForProduct, this.getQuantity());
            }
            this.clearSearchAndQty();
        }
        this.ngAfterViewInit();
    }

    setErrorMessage(message: string) {
        this.errorMessage = message;
    }

    searchForProductByCIN(userInputForCIN: string, quantity: number) {
        this.productsService.getProductByCIN(Number(userInputForCIN))
            //.retry(2)
            .subscribe(
                (data: Product) => {
                    if (data.cin !== null) {
                        if (!this.isC2Product(data)) {
                            data.quantity = quantity;
                            data.ndc = FormatUtil.formatNdc(data.ndc);
                            this.communicationService.productSearched(data);
                        } else {
                            this.setErrorMessage(AppErrorConstants.C2_PRODUCT.value);
                        }
                    } else {
                        this.communicationService.productSearched(
                            this.createInvalidProduct(userInputForCIN, quantity, Constants.BY_CIN.value));
                    }
                },
                error => {
                    this.setErrorMessage(AppErrorConstants.SEARCH_FAILED.value);
                }
            );
    }

    searchForProductByNdcOrUpc(ndcOrUpc, quantity: number) {
        this.productsService.getProductByNDCorUPC(ndcOrUpc.toString())
            //.retry(2)
            .subscribe(
                (data: Product[]) => {
                    this.validateResults(data, ndcOrUpc, quantity);
                },
                error => {
                    this.setErrorMessage(AppErrorConstants.SEARCH_FAILED.value);
                }
            );
    }

    validateResults(products: Product[], ndcOrUpc: string, quantity: number) {
        if (products.length > 0) {
            if (products.length === 1) {
                if (!this.isC2Product(products[0])) {
                    products[0].quantity = quantity;
                    products[0].ndc = FormatUtil.formatNdc(products[0].ndc);
                    this.communicationService.productSearched(products[0]);
                } else {
                    this.setErrorMessage(AppErrorConstants.C2_PRODUCT.value);
                }
            } else {
                this.setErrorMessage(AppErrorConstants.MULTIPLE_PRODUCT_ENUM.value);
            }
        } else {
            this.communicationService.productSearched(this.createInvalidProduct(ndcOrUpc, quantity, Constants.BY_NDC.value));
        }
    }

    createInvalidProduct(itemNumber: string, quantity: number, type: string): Product {
        let cin = type === Constants.BY_CIN.value ? Number(itemNumber) : null;
        let ndc = type === Constants.BY_NDC.value ? FormatUtil.formatNdc(itemNumber) : null;
        return new Product(cin, ndc, AppErrorConstants.INVALID_PRODUCT.value, quantity, null, true);
    }

    getQuantity(): number {
        return (this.quantity != null  && Number(this.quantity) > 0) ? this.quantity : 1;
    }

    clearInputs() {
        this.product = null;
        this.quantity = null;
        this.poNumber = '';
        this.setErrorMessage('');
        this.ngAfterViewInit();
        this.communicationService.currentPONumber(this.poNumber);
    }

    clearSearchAndQty() {
        this.product = null;
        this.quantity = null;
    }

    isC2Product(product: Product): boolean {
        return product.deaSchedule.deaScheduleNum === 2 || product.deaSchedule.deaScheduleNum === 6;
    }

    onPaste(e: any) {
        e.preventDefault();
        return false;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
