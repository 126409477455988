export class OrderProduct {
    cin: number;
    quantity: number;
    schedule: number;

    constructor(cin?: number, quantity?: number, schedule?: number) {
        this.cin = cin;
        this.quantity = quantity;
        this.schedule = schedule;
    }
}
