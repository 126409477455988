import {Enum} from './enume';

export class ModalMessageConstants extends Enum<string> {

    public static readonly DELETE_MODAL_MESSAGE = new Enum('Selected items in your order will be <br/>deleted. This cannot be undone.');
    public static readonly CLEAR_MODAL_MESSAGE = new Enum('All items in your order will be <br/>cleared. This cannot be undone.');
    public static readonly ORDER_SUBMISSION_SUCCESS =
        new Enum('Your Order has successfully been <br/>submitted to Cardinal Health.<br/><br/>');
    public static readonly ORDER_SUBMISSION_ERROR = new Enum('Unable to submit your order. <br/>Please try again later.');
    public static readonly PO_REQUIRED_MESSAGE = new Enum('PO is required to submit your order. <br/>Please enter a PO in the PO Field.');
    public static readonly ALL_ITEMS_INVALID = new Enum('All items in your order are invalid. <br/>Please add a valid item and submit.');
    public static readonly INVALID_FILE_MESSAGE =
        new Enum('All items in the imported file are invalid. <br/>Please import a valid file and retry.');
    public static readonly IMPORT_FAILED_MESSAGE = new Enum('Unable to import your order. <br/>Please try again later');
}
