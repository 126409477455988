import {Enum} from './enume';

export class AppErrorConstants extends Enum<string> {

    public static readonly MIN_LENGTH_PRODUCT_SEARCH = new Enum('Please enter at least 7 digits for CIN, NDC or UPC.');
    public static readonly SEARCH_FAILED = new Enum('Sorry. We are currently unable to add items to your order.');
    public static readonly MULTIPLE_PRODUCT_ENUM = new Enum('Product Not Added: Multiple products were found with that NDC or UPC. ' +
        'Please enter a CIN and try again.');
    public static readonly INVALID_PRODUCT = new Enum('INVALID PRODUCT');
    public static readonly MAX_LIMIT_REACHED =
        new Enum('You have exceeded the 350 maximum products allowed. please delete a product and try again.');
    public static readonly C2_PRODUCT = new Enum('Controlled substances are not available to order.');
    public static readonly UNKNOWN_ITEM = new Enum('Unknown Item. Multiple products were found with this NDC or UPC');
    public static readonly UNABLE_TO_RETRIEVE_ACCOUNTS = new Enum('Unable to retrieve accounts. Please try again.');
}
