import {Component, ElementRef, ViewChild, OnDestroy} from '@angular/core';
import {Product} from '../model/product';
import {Constants} from '../constants/app.constants';
import {ModalCommunicationService} from '../services/modalcommunication.service';
import {ComponentCommunicationService} from '../services/componentcommunication.service';
import {ModalType} from '../model/modaltype';
import {ModalMessageConstants} from '../constants/modal.constants';
import {Subscription} from 'rxjs';
import {OrderImportService} from '../services/orderimport.service';
import {FormatUtil} from '../util/foramtutil';

@Component({
    selector: 'my-alt-ordering-order-import',
    templateUrl: './orderimport.component.html',
    styleUrls: ['./orderimport.component.scss']
})

export class OrderImportComponent implements OnDestroy {

    @ViewChild('fileInput') fileInput: ElementRef;

    poNumber: string;
    importedOrderLines: any[];
    productsToSearch: any[] = [];
    modalSubscription: Subscription;
    importing: boolean;

    constructor(private modalCommService: ModalCommunicationService,
                private compCommService: ComponentCommunicationService,
                private orderImportService: OrderImportService) {
        this.takeAction();
    }

    takeAction() {
        this.modalSubscription = this.modalCommService.action$.subscribe(
            action => {
                switch (action) {
                    case Constants.FOCUS_SEARCH.value:
                        this.compCommService.focusBackToSearch(true);
                        break;
                    default:
                        console.log('Nothing to do.');
                }
            });
    }

    openImportDialog() {
        this.fileInput.nativeElement.click();
    }

    onChange(fileInput) {
        this.importing = true;
        let files = fileInput.files;
        let that = this;
        let fileReader = new FileReader();
        fileReader.onload = function(e) {
            that.validateFile(fileReader.result);
        };
        fileReader.readAsText(files[0]);
    }

    validateFile(text: any) {
        let lines = this.getSplitLines(text);
        console.log('PO Line is:::' + lines[0]);
        if (lines.length > 0) {
            if (this.isTherePO(lines[0])) {
                this.poNumber = this.getPO(lines[0]);
                lines.splice(0, 1);
            } else {
                this.poNumber = '';
            }
            this.importedOrderLines = this.getOrderLines(lines);
            if (this.importedOrderLines.length > 0) {
                this.searchForImportedProducts(this.productsToSearch);
            } else {
                this.importing = false;
                this.modalCommService.showModal(this.buildInvalidFileModal());
            }
        } else {
            this.importing = false;
            this.modalCommService.showModal(this.buildInvalidFileModal());
        }
        this.fileInput.nativeElement.value = '';
    }

    getSplitLines(text: any): string[] {
        return text.split(/[\r\n]+/g);
    }

	getPO(line: string) {
		//OE-7439 || Publix || Emergency Ordering - Autogenerate Purchase Order Number
		// Not to display PO Number for PUBLIX in textbox
		if (null == localStorage.getItem('publix-building')) {
			if (line.substr(0, 2) === Constants.PO_ID.value) {
				return line.substr(2, line.length);
			} else if (line.substr(0, 4) === Constants.MO_PO_ID.value) {
				return line.substr(4, line.length);
			}
		}
    }

    getOrderLines(lines: string[]): any[] {
        let orderLinesFromFile = [];
        for (let line of lines) {
            if (this.isThereItem(line)) {
                let orderLine = this.getProductIDAndQty(line.substr(2, line.length));
                orderLinesFromFile.push(orderLine);
            }
        }
        return orderLinesFromFile;
    }

    isTherePO(line: string): boolean {
        return line.substr(0, 2) === Constants.PO_ID.value || line.substr(0, 4) === Constants.MO_PO_ID.value;
    }

    isThereItem(line: string): boolean {
        return line.substr(0, 2) === Constants.ITEM_ID.value;
    }

    getProductIDAndQty(line: string): any {
        let qty = line.substr(line.length - 3, line.length);
        let productId = line.substr(0, line.length - 3);
        this.productsToSearch.push(productId);
        let productIdAndQty: { [key: string]: string; } = {};
        productIdAndQty[productId] = qty;
        return productIdAndQty;
    }

    searchForImportedProducts(productsToSearch: string[]) {
        this.orderImportService.searchForImportedProducts(productsToSearch)
            //.retry(2) //Changes done for Angular6 upgrade
            .subscribe(
            (data: Product[]) => {
                this.applyQtyAndPropagate(data);
            },
            error => {
                this.modalCommService.showModal(this.buildImportFailedModal());
                this.resetImport();

            }
        );
    }

    applyQtyAndPropagate(products: Product[]) {
        for (let index in products) {
            products[index].quantity = this.getQuantity(products[index]);
            products[index].ndc = FormatUtil.formatNdc(products[index].ndc);
        }
        this.compCommService.multipleProducts(products);
        this.compCommService.currentPONumber(this.poNumber);
        this.resetImport();
    }

    resetImport() {
        this.importedOrderLines = [];
        this.productsToSearch = [];
        this.importing = false;
    }

    getQuantity(product: Product) {
        for (let index in this.importedOrderLines) {
            if (this.importedOrderLines[index][product.cin]) {
                return Number(this.importedOrderLines[index][product.cin]) ?
                    Number(this.importedOrderLines[index][product.cin])
                    : 1;
            } else if (this.importedOrderLines[index][product.ndc]) {
                return Number(this.importedOrderLines[index][product.ndc]) ?
                    Number(this.importedOrderLines[index][product.ndc])
                    : 1;
            } else {
                let upc = product.upc && product.upc != null ? product.upc.trim() : product.upc;
                if (this.importedOrderLines[index][upc]) {
                    return Number(this.importedOrderLines[index][upc]) ?
                        Number(this.importedOrderLines[index][upc])
                        : 1;
                }
            }
        }
        return null;
    }

    buildInvalidFileModal() {
        return new ModalType(Constants.INFORMATIONAL.value,
            ModalMessageConstants.INVALID_FILE_MESSAGE.value,
            Constants.NONE_ACTION_TYPE.value,
            Constants.FOCUS_SEARCH.value);
    }

    buildImportFailedModal() {
        return new ModalType(Constants.INFORMATIONAL.value,
            ModalMessageConstants.IMPORT_FAILED_MESSAGE.value,
            Constants.NONE_ACTION_TYPE.value,
            Constants.FOCUS_SEARCH.value);
    }

    ngOnDestroy() {
        this.modalSubscription.unsubscribe();
    }
}
