import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {ModalType} from '../model/modaltype';

@Injectable()
export class ModalCommunicationService {

    // Observable sources
    private showModalSource = new Subject<ModalType>();
    private actionSource = new Subject<string>();

    // Observable streams
    show$ = this.showModalSource.asObservable();
    action$ = this.actionSource.asObservable();

    showModal(modalType: ModalType) {
        this.showModalSource.next(modalType);
    }

    takeAppropriateAction(actionType: string) {
        this.actionSource.next(actionType);
    }
}
