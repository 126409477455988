import {Injectable} from '@angular/core';
import {Environment} from '../env/environment';
import {Http, Response, Headers, RequestOptions, RequestOptionsArgs} from '@angular/http';
import {Observable} from 'rxjs';
import {OEStatusWithRemainingTime} from '../model/oestatuswithremainingtime';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class OeStatusService {

    private host: string;
    private apiKey: string;

    constructor(private http: HttpClient, private environment: Environment) {
        this.host = environment.getEnvironmentVariable('oeStatusEndPoint');
        this.apiKey = environment.getEnvironmentVariable('apiKey');
    }

    getAppStatus(app: string): Observable<any> {
        let options = this.generateOptionsHeaders();
        return this.http.get(this.host + '/status/' + app, options).pipe(map((response) => response));
    }

    generateOptionsHeaders() {
    let headers = new HttpHeaders({
        'Content-Type' : 'application/json',
        'Accept' : 'application/json',
        'x-api-key' : this.apiKey
    });
        
    let options = {          
            headers: headers
         };
        return options;
    }
}
