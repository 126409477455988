import {Component, OnInit, OnDestroy} from '@angular/core';
import '../style/app.scss';
import {Router, NavigationEnd, ActivatedRouteSnapshot} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {ModalCommunicationService} from './services/modalcommunication.service';
import {ComponentCommunicationService} from './services/componentcommunication.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        ComponentCommunicationService,
        ModalCommunicationService
    ]
})
export class AppComponent implements OnInit, OnDestroy {

    subscription: Subscription;

    constructor(private router: Router,
                private titleService: Title) {}

    ngOnInit() {
        this.getTitle();
    }

    getTitle() {
        this.subscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.titleService.setTitle(this.getDeepestTitle(this.router.routerState.snapshot.root));
            }
        });
    }

    private getDeepestTitle(routeSnapshot: ActivatedRouteSnapshot) {
        let title = routeSnapshot.data['title'];
        if (routeSnapshot.firstChild) {
            title = this.getDeepestTitle(routeSnapshot.firstChild) || title;
        }
        return title;
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
