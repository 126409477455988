import {NgModule, ApplicationRef} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {AppComponent} from './app.component';
import {routing} from './app.routing';
import {removeNgStyles, createNewHosts} from '@angularclass/hmr';
import {LoginComponent} from './login/login.component';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {AuthenticationService} from './services/authentication.service';
import {AccountsService} from './services/accounts.service';
import {HeaderComponent} from './header/header.component';
import {AccountsComponent} from './accounts/accounts.component';
import {SearchComponent} from './search/search.component';
import {ClickOutsideModule} from 'ng2-click-outside';
import {ModalModule} from 'ngx-bootstrap/modal';
import {OrderImportComponent} from './orderimport/orderimport.component';
import {AuthGuard} from './guards/auth.guard';
import {HomeComponent} from './home/home.component';
import {ErrorComponent} from './error/error.component';
import {ProductsService} from './services/products.service';
import {OrdersService} from './services/orders.service';
import {Environment} from './env/environment';
import {OrderListComponent} from './orderlist/orderlist.component';
import {ModalComponent} from './modal/modal.component';
import {OrderImportService} from './services/orderimport.service';
import {FooterComponent} from './footer/footer.component';
import {OeStatusService} from './services/oestatus.service';
import {OeStatusGuard} from './guards/oestatus.guard';
import {AuthorizationService} from './services/authorization.service';
import {RedirectService} from './services/redirect.service';
import {CustomHttpService} from './services/customhttp.service';
import {RouterModule} from '@angular/router';



@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        ClickOutsideModule,
        routing,
        ModalModule.forRoot()
    ],
    declarations: [
        AppComponent,
        LoginComponent,
        HeaderComponent,
        AccountsComponent,
        SearchComponent,
        OrderImportComponent,
        HomeComponent,
        ErrorComponent,
        OrderListComponent,
        ModalComponent,
        FooterComponent
    ],
    providers: [
        Title,
        AuthenticationService,
        AccountsService,
        AuthGuard,
        ProductsService,
        OrdersService,
        Environment,
        OrderImportService,
        OeStatusService,
        OeStatusGuard,
        AuthorizationService,
        RedirectService,
        CustomHttpService
        ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(public appRef: ApplicationRef) {}
    hmrOnInit(store) {
        console.log('HMR store', store);
    }
    hmrOnDestroy(store) {
        let cmpLocation = this.appRef.components.map(cmp => cmp.location.nativeElement);
        // recreate elements
        store.disposeOldHosts = createNewHosts(cmpLocation);
        // remove styles
        removeNgStyles();
    }
    hmrAfterDestroy(store) {
        // display new elements
        store.disposeOldHosts();
        delete store.disposeOldHosts;
    }
}
