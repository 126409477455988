import {Injectable} from '@angular/core';
import {Response} from '@angular/http';
import {Environment} from '../env/environment';
import {Observable} from 'rxjs';
import {Product} from '../model/product';
import {CustomHttpService} from './customhttp.service';
import { map } from 'rxjs/operators';
// import 'rxjs/add/operator/map';

@Injectable()
export class OrderImportService {

    private host: string;

    constructor(private http: CustomHttpService, private enviroment: Environment) {
        this.host = enviroment.getEnvironmentVariable('orderImportServiceEndPoint');
    }
    //OE-3083 Modified for Angular6 upgrade
    searchForImportedProducts(products: string[]): Observable<any> {
        return this.http.post(this.host + '/order-import/products', products).pipe(map((response) => response));
    }
}
