import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {HomeComponent} from './home/home.component';
import {ErrorComponent} from './error/error.component';
import {AuthGuard} from './guards/auth.guard';
import {OeStatusGuard} from './guards/oestatus.guard';



const routes: Routes = [
    
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [OeStatusGuard],
        data: {
            title: 'Emergency Ordering - Sign In'
        }
    },
    {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthGuard],
        data: {
            title: 'Emergency Ordering - Home'
        }
    },
    {
        path: 'error',
        component: ErrorComponent,
        data: {
            title: 'Emergency Ordering - Error'
        }
    },
    {
        path: '**',
        redirectTo: 'home'
    }
];

export const routing = RouterModule.forRoot(routes);
