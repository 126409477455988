import {Injectable} from '@angular/core';

@Injectable()
export class Environment {

    public getEnvironmentVariable(value) {
        let data = {};
        let environment = this.getCurrentEnvironment();
        console.log('Value of ENV : ' + environment);
        switch (environment) {
            case'localhost':
                data = {
                    productsServiceEndpoint: 'http://localhost:8083/api/v1',
                    orderServiceEndPoint: 'http://localhost:8084/api/v1',
                    orderImportServiceEndPoint: 'http://localhost:8085/api/v1',
                    oeStatusEndPoint: 'http://localhost:9900/api/v1',
                    baseAuthEndpoint: 'https://cardinalb2b.oktapreview.com',
                    clientId: '3bnFDbGiUMXNNrjmYIYh',
                    authorizationEndPoint: 'http://localhost:8092/api/v1',
                    accountsServiceEndPoint: 'http://localhost:8666/api/v1',
                    redirectUri: 'http:localhost:8088',
                    oe: 'https://www1devtemp.cardinalhealth.com/eps/mycah',
                    tokenGenerator: 'http://localhost:8090/api/v1',
                    apiKey: '2jLT40S1yQ1kH9R3SB5jl4LDge1bawJB8TL7SP3n'
                };
                break;
            case 'emergencyordering-dev.cardinalhealth.net':
                data = {
                    productsServiceEndpoint: 'https://yfhrwdnqw3.execute-api.us-east-1.amazonaws.com/alt_ordering_dev/api/v1',
                    orderServiceEndPoint: 'https://8gdfpu5cpc.execute-api.us-east-1.amazonaws.com/alt_ordering_dev/api/v1',
                    orderImportServiceEndPoint: 'https://i5d6w5k5ti.execute-api.us-east-1.amazonaws.com/alt_ordering_dev/api/v1',
                    oeStatusEndPoint: 'https://i30y1vgwx0.execute-api.us-east-1.amazonaws.com/alt_ordering_dev/api/v1',
                    baseAuthEndpoint: 'https://cardinalb2b.oktapreview.com',
                    clientId: '3bnFDbGiUMXNNrjmYIYh',
                    authorizationEndPoint: 'https://dgu6e1y4sd.execute-api.us-east-1.amazonaws.com/alt_ordering_dev/api/v1',
                    accountsServiceEndPoint: 'https://43ked5x5x2.execute-api.us-east-1.amazonaws.com/alt_ordering_dev/api/v1',
                    redirectUri: 'https://emergencyordering-dev.cardinalhealth.net',
                    oe: 'https://www1devtemp.cardinalhealth.com/eps/mycah',
                    tokenGenerator: 'https://mi9m0lwtc2.execute-api.us-east-1.amazonaws.com/alt_ordering_dev/api/v1',
                    apiKey: '2jLT40S1yQ1kH9R3SB5jl4LDge1bawJB8TL7SP3n'

                };
                break;
            case 'emergencyordering-dev.apps.cf.np.cahcloud.net':
                data = {
                    productsServiceEndpoint: 'https://yfhrwdnqw3.execute-api.us-east-1.amazonaws.com/alt_ordering_dev/api/v1',
                    orderServiceEndPoint: 'https://8gdfpu5cpc.execute-api.us-east-1.amazonaws.com/alt_ordering_dev/api/v1',
                    orderImportServiceEndPoint: 'https://i5d6w5k5ti.execute-api.us-east-1.amazonaws.com/alt_ordering_dev/api/v1',
                    oeStatusEndPoint: 'https://i30y1vgwx0.execute-api.us-east-1.amazonaws.com/alt_ordering_dev/api/v1',
                    baseAuthEndpoint: 'https://cardinalb2b.oktapreview.com',
                    clientId: '3bnFDbGiUMXNNrjmYIYh',
                    authorizationEndPoint: 'https://dgu6e1y4sd.execute-api.us-east-1.amazonaws.com/alt_ordering_dev/api/v1',
                    accountsServiceEndPoint: 'https://43ked5x5x2.execute-api.us-east-1.amazonaws.com/alt_ordering_dev/api/v1',
                    redirectUri: 'https://emergencyordering-dev.apps.cf.np.cahcloud.net',
                    oe: 'https://www1devtemp.cardinalhealth.com/eps/mycah',
                    tokenGenerator: 'https://mi9m0lwtc2.execute-api.us-east-1.amazonaws.com/alt_ordering_dev/api/v1',
                    apiKey: '2jLT40S1yQ1kH9R3SB5jl4LDge1bawJB8TL7SP3n'

                };
                break;
            case 'emergencyordering-qa.apps.cf.np.gc.cahcloud.net':
                data = {
                    productsServiceEndpoint: 'https://api.dev.cardinalhealth.com/pharma/ecomm/eo/products/api/v1',
                    orderServiceEndPoint: 'https://api.dev.cardinalhealth.com/pharma/ecomm/eo/orders/api/v1',
					orderImportServiceEndPoint: 'https://api.dev.cardinalhealth.com/pharma/ecomm/eo/orders-import/api/v1',
                    oeStatusEndPoint: 'https://api.dev.cardinalhealth.com/pharma/ecomm/eo/status/api/v1',
                    baseAuthEndpoint: 'https://myidb2bstg.cardinalhealth.com',
                    clientId: 'BId1ZkWa1HC5JZd0XDIK',
                    authorizationEndPoint: 'https://api.dev.cardinalhealth.com/pharma/ecomm/eo/auth/api/v1',
					accountsServiceEndPoint: 'https://api.dev.cardinalhealth.com/pharma/ecomm/eo/accounts/api/v1',
                    redirectUri: 'https://emergencyordering-qa.cardinalhealth.com',
                    oe: 'https://orderexpresspmodstg.cardinalhealth.com',
                    tokenGenerator: 'https://api.dev.cardinalhealth.com/pharma/ecomm/eo/token-generator/api/v1',
                    apiKey: '9ZKqyAXTcx6hbDJQyBe6cedCMDgpxKlXoBK2czT4ruWECodX'                        
                };
                break;      
			case 'emergencyordering-qa.cardinalhealth.com':
                data = {
                    productsServiceEndpoint: 'https://api.dev.cardinalhealth.com/pharma/ecomm/eo/products/api/v1',
                    orderServiceEndPoint: 'https://api.dev.cardinalhealth.com/pharma/ecomm/eo/orders/api/v1',
					orderImportServiceEndPoint: 'https://api.dev.cardinalhealth.com/pharma/ecomm/eo/orders-import/api/v1',
                    oeStatusEndPoint: 'https://api.dev.cardinalhealth.com/pharma/ecomm/eo/status/api/v1',
                    baseAuthEndpoint: 'https://myidb2bstg.cardinalhealth.com',
                    clientId: 'BId1ZkWa1HC5JZd0XDIK',
                    authorizationEndPoint: 'https://api.dev.cardinalhealth.com/pharma/ecomm/eo/auth/api/v1',
					accountsServiceEndPoint: 'https://api.dev.cardinalhealth.com/pharma/ecomm/eo/accounts/api/v1',
                    redirectUri: 'https://emergencyordering-qa.cardinalhealth.com',
                    oe: 'https://orderexpresspmodstg.cardinalhealth.com',
                    tokenGenerator: 'https://api.dev.cardinalhealth.com/pharma/ecomm/eo/token-generator/api/v1',
                    apiKey: '9ZKqyAXTcx6hbDJQyBe6cedCMDgpxKlXoBK2czT4ruWECodX'                        
                };
                break;      
            
            case 'emergencyordering-stg.cardinalhealth.com':
                data = {
                    productsServiceEndpoint: 'https://api.stage.cardinalhealth.com/pharma/ecomm/eo/products/api/v1',
                    orderServiceEndPoint: 'https://api.stage.cardinalhealth.com/pharma/ecomm/eo/orders/api/v1',
					orderImportServiceEndPoint: 'https://api.stage.cardinalhealth.com/pharma/ecomm/eo/orders-import/api/v1',
                    oeStatusEndPoint: 'https://api.stage.cardinalhealth.com/pharma/ecomm/eo/status/api/v1',
                    baseAuthEndpoint: 'https://myidb2bstg.cardinalhealth.com',
                    clientId: 'BId1ZkWa1HC5JZd0XDIK',
					authorizationEndPoint: 'https://api.stage.cardinalhealth.com/pharma/ecomm/eo/auth/api/v1',
					accountsServiceEndPoint: 'https://api.stage.cardinalhealth.com/pharma/ecomm/eo/accounts/api/v1',
                    redirectUri: 'https://emergencyordering-stg.cardinalhealth.com',
                    oe: 'https://orderexpresspmodstg.cardinalhealth.com',
					tokenGenerator: 'https://api.stage.cardinalhealth.com/pharma/ecomm/eo/token-generator/api/v1',
                    apiKey: 'AN7vcB2aeDwKFGGTG12c3qi8OCJAtxT0SNMANYzRURvYZeMu'
                };
                break;
            
            case 'emergencyordering.cardinalhealth.com':
                data = {
                    productsServiceEndpoint: 'https://api.cardinalhealth.com/pharma/ecomm/eo/products/api/v1',
                    orderServiceEndPoint: 'https://api.cardinalhealth.com/pharma/ecomm/eo/orders/api/v1',
					orderImportServiceEndPoint: 'https://api.cardinalhealth.com/pharma/ecomm/eo/orders-import/api/v1',
                    oeStatusEndPoint: 'https://api.cardinalhealth.com/pharma/ecomm/eo/status/api/v1',
                    baseAuthEndpoint: 'https://myidb2b.cardinalhealth.com',
                    clientId: 'RiOYkyCi8M1swkCtxm4J',
                    authorizationEndPoint: 'https://api.cardinalhealth.com/pharma/ecomm/eo/auth/api/v1',
					accountsServiceEndPoint: 'https://api.cardinalhealth.com/pharma/ecomm/eo/accounts/api/v1',
                    redirectUri: 'https://emergencyordering.cardinalhealth.com',
                    oe: 'https://orderexpress.cardinalhealth.com',
                    tokenGenerator: 'https://api.cardinalhealth.com/pharma/ecomm/eo/token-generator/api/v1',
                    apiKey: 'XHJrhWekR2o4gziHSCu5vIhGyu09o0G6I9hC4Uf1HAtPLaYE'
                };
                break;
            default:
                data = {
                    productsServiceEndpoint: 'https://api.stage.cardinalhealth.com/pharma/ecomm/eo/products/api/v1',
                    orderServiceEndPoint: 'https://api.stage.cardinalhealth.com/pharma/ecomm/eo/orders/api/v1',
					orderImportServiceEndPoint: 'https://api.stage.cardinalhealth.com/pharma/ecomm/eo/orders-import/api/v1',
                    oeStatusEndPoint: 'https://api.stage.cardinalhealth.com/pharma/ecomm/eo/status/api/v1',
                    baseAuthEndpoint: 'https://myidb2bstg.cardinalhealth.com',
                    clientId: 'BId1ZkWa1HC5JZd0XDIK',
					authorizationEndPoint: 'https://api.stage.cardinalhealth.com/pharma/ecomm/eo/auth/api/v1',
					accountsServiceEndPoint: 'https://api.stage.cardinalhealth.com/pharma/ecomm/eo/accounts/api/v1',
                    redirectUri: 'https://emergencyordering-stg.cardinalhealth.com',
                    oe: 'https://orderexpresspmodstg.cardinalhealth.com',
					tokenGenerator: 'https://api.stage.cardinalhealth.com/pharma/ecomm/eo/token-generator/api/v1',
                    apiKey: 'AN7vcB2aeDwKFGGTG12c3qi8OCJAtxT0SNMANYzRURvYZeMu'
                };
                break;
        }
        return data[value];
    }

    getCurrentEnvironment(): string {
        console.log('location hostname will be : ' + window.location.hostname);
        return window.location.hostname;
    }
}
