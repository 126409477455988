import {Component, ViewChild, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {ModalCommunicationService} from '../services/modalcommunication.service';
import {ModalType} from '../model/modaltype';
import { ModalDirective } from 'ngx-bootstrap/modal';


@Component({
    selector: 'my-alt-orderding-modal',
    templateUrl: './modal.component.html'
})

export class ModalComponent implements OnDestroy {

    @ViewChild('childModal') childModal: ModalDirective;
    subscription: Subscription;
    modalType: ModalType;

    constructor(private modalService: ModalCommunicationService) {
        this.modalType = new ModalType();
        this.showModal();
    }

    showModal() {
        this.subscription = this.modalService.show$.subscribe(
            modalType => {
                this.modalType = modalType;
                this.childModal.show();
            });
    }

    takeAction(action: string) {
        this.childModal.ngOnDestroy();
        this.modalService.takeAppropriateAction(action);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}
