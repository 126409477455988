import {Injectable, Optional} from '@angular/core';

import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Environment} from '../env/environment';
import {AuthorizationService} from './authorization.service';
import {Observable, throwError} from 'rxjs';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import {catchError, mergeMap} from "rxjs/operators";
import 'rxjs/add/operator/mergeMap';

@Injectable()
export class CustomHttpService {

    private tokenGeneratorEndpoint: string;
    private apiKey: string;

    constructor(private http: HttpClient,
                private environment: Environment,
                private authorizationService: AuthorizationService) {
        this.tokenGeneratorEndpoint = environment.getEnvironmentVariable('tokenGenerator') + '/token-generator/token';
        this.apiKey = environment.getEnvironmentVariable('apiKey');
    }
    //OE-3083 Modified for Angular6 upgrade
    generateOptionsHeaders(options?: Object) {
        let appToken = localStorage.getItem('app-token');
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'x-api-key': this.apiKey,
            'Authorization': appToken !== null ? appToken : ''
        });
         options = {
            headers: headers
         };
        
        return options;
    }

    get(url: string, options?: Object): Observable<any> {
        
        
        if (!options) {
            options = this.appendUserIdToRequestHeader(options);
        }
        return this.http.get(url, options).pipe(catchError(error => {
            if (error.status === 401) {
                return this.http.get(this.tokenGeneratorEndpoint, this.getOptionsHeadersForTokenGenerator()).pipe(mergeMap(appToken => {
                    let newToken = appToken;
                    if (newToken) {
                        options = this.updateAccessToken(newToken, options);
                        return this.http.get(url, options);
                    }
                    return throwError(catchError);
                }));
            }
            return throwError(catchError);
        }));
    }

    post(url: string, body: any, options?: Object): Observable<any> {
        if (!options) {
            options = this.appendUserIdToRequestHeader(options);
        }
        return this.http.post(url, body, options).pipe(catchError(error => {
            if (error.status === 401) {
                return this.http.get(this.tokenGeneratorEndpoint, this.getOptionsHeadersForTokenGenerator()).pipe(mergeMap(appToken => {
                    let newToken = appToken;
                    if (newToken) {
                        options = this.updateAccessToken(newToken, options);
                        return this.http.post(url, body, options);
                    }
                    return throwError(error);
                }));
            }
            return throwError(error);
        }));
    }
    //OE-3083 Modified for Angular6 upgrade
    appendUserIdToRequestHeader(options: Object) {
        let user = JSON.parse(localStorage.getItem('currentUser'));
        let appToken = localStorage.getItem('app-token');
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'x-api-key': this.apiKey,
            'Authorization': appToken !== null ? appToken : '',
            'user-id': user !== null ? user : ''
        });

        options = {
            headers: headers
        };
     console.log(options);
        return options;
    }
    //OE-3083 Modified for Angular6 upgrade
    updateAccessToken(appToken, options: Object) {
        localStorage.removeItem('app-token');
        localStorage.setItem('app-token', appToken);
        let headers = new HttpHeaders();
        headers.delete('Authorization');
        headers.append('Authorization', localStorage.getItem('app-token'));
        options = {
            headers: headers
         };
        return options;
    }
    //OE-3083 Modified for Angular6 upgrade
    getOptionsHeadersForTokenGenerator() {
        let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'x-api-key': this.apiKey,
        'id-token': this.authorizationService.getIdToken(),
        'access-token': this.authorizationService.getAccessToken()
        });
        let options = {
            headers: headers
         };
        return options;
    }

}
