import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import {Environment} from '../env/environment';
import {Observable, combineLatest} from 'rxjs';
import { from } from 'rxjs';
import { tap } from 'rxjs/operators';

let OktaAuth = require('@okta/okta-auth-js/dist/okta-auth-js.min.js');

@Injectable()
export class AuthenticationService {

    private baseAuthEndpoint: string;
    private clientId: string;
    private authClient: any;
    private redirectUri: string;

    constructor(private environment: Environment) {
        this.baseAuthEndpoint = environment.getEnvironmentVariable('baseAuthEndpoint');
        this.clientId = environment.getEnvironmentVariable('clientId');
        this.redirectUri = environment.getEnvironmentVariable('redirectUri');
        this.authClient = new OktaAuth({
            url: this.baseAuthEndpoint,
            clientId: this.clientId
        });


    }

    login(username: string, password: string): Observable<any> {
        return from(this.authClient.signIn({
            username: username,
            password: password
        }));
    }

    generateIdToken(sessionToken: string): Observable<any> {
        return from(this.authClient.token.getWithoutPrompt({
            responseType: ['id_token', 'token'],
            scopes: ['openid', 'profile'],
            sessionToken: sessionToken,
            redirectUri: this.redirectUri
        }));
    }
    //Changes done for UPMC SSO OE-3083 starts
    //Authorize call for IdToken and AccessToken
    generateIdTokenForSSO(): Observable<any> {
        return from(this.authClient.token.getWithoutPrompt({
            responseType: ['id_token', 'token'],
            scopes: ['openid', 'profile'],
            redirectUri: this.redirectUri,
            clientId: this.clientId
        }));
    }
    //Okta Session/me call
    makeSessionMeCallForSSO(): Observable<any>{
        return from(this.authClient.session.get());
    }
    //Changes done for UPMC SSO OE-3083 ends
    verifyToken() {
        let fromMng = this.authClient.tokenManager.get('idT');
        if (fromMng) {
            let idtObject = {
                idToken: fromMng.idToken,
                clientId: fromMng.clientId,
                issuer: fromMng.issuer
            };
            let obs1 = from(
                this.authClient.token.verify(idtObject)
            );
			let obs2 = this.getUserFromAccessToken();
			//OE-7438 || Publix || Emergency Ordering- Single Sign-On (SSO) and Role-Based User Experience Customization - start
			return combineLatest([obs1, obs2]).pipe(
				tap(([data1, data2]) => {
					if (data2) {
						if (typeof data2.Building !== 'undefined') {
							localStorage.setItem('publix-building', data2.Building);
						}
						if (typeof data2.PublixRole !== 'undefined') {
							localStorage.setItem('publix-role', data2.PublixRole);
						}
					}
				})
			);
			//OE-7438 || Publix || Emergency Ordering- Single Sign-On (SSO) and Role-Based User Experience Customization - end
        }
    }

    getUserFromIDToken() {
        let fromMng = this.authClient.tokenManager.get('idT');
        return this.authClient.token.decode(fromMng.idToken).payload.sub;
    }

    getIdTokenExpiration(): number {
        let fromMng = this.authClient.tokenManager.get('idT');
        return this.authClient.token.decode(fromMng.idToken).payload.exp;
    }
	//OE-7438 || Publix || Emergency Ordering- Single Sign-On (SSO) and Role-Based User Experience Customization
    //Added Observable<any>
    getUserFromAccessToken(): Observable<any>{
        let fromMng = this.authClient.tokenManager.get('at');
        let atObject = {
            accessToken: fromMng.accessToken,
            userinfoUrl: fromMng.userinfoUrl
        };
        return from(
            this.authClient.token.getUserInfo(atObject)
        );
    }

    addIdTokenToManager(idToken: any) {
        this.authClient.tokenManager.add('idT', idToken);
    }

    addAccessTokenToManager(accessToken: any) {
        this.authClient.tokenManager.add('at', accessToken);
    }

    getIdTokenFromManager() {
        return this.authClient.tokenManager.get('idT');
    }

    getAccessTokenFromManager() {
        return this.authClient.tokenManager.get('at');
    }

    logout() {
        console.log('Signing out...');
        return from(this.authClient.signOut());
    }

    clearTokens() {
        this.authClient.tokenManager.clear();
        localStorage.clear();
    }

    refreshTokens(): Observable<any> {

        let obs1 = from(
            this.authClient.tokenManager.refresh('idT')
        );
        let obs2 = from(
            this.authClient.tokenManager.refresh('at')
        );
        return combineLatest([obs1, obs2]);
    }
}
