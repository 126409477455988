import {Injectable} from '@angular/core';
import {Environment} from '../env/environment';

@Injectable()
export class RedirectService {

    private redirectTo: string;

    constructor(private environment: Environment) {
        this.redirectTo = environment.getEnvironmentVariable('oe');
    }

    redirectToOE() {
        window.location.href = this.redirectTo;
    }
}
