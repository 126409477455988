import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import {Observable} from 'rxjs';
import {Account} from '../model/account';
import {Environment} from '../env/environment';
import {CustomHttpService} from './customhttp.service';
import { map } from 'rxjs/operators';
import { AuthGuard } from '../guards';
@Injectable()
export class AccountsService {

    private accountsServiceEndpoint: string;

    constructor(private http: CustomHttpService, private environment: Environment, private authGuard: AuthGuard) {
        this.accountsServiceEndpoint = environment.getEnvironmentVariable('accountsServiceEndPoint');
    }
    //OE-3083 Modified for Angular6 upgrade
    
    //OE-7438 || Publix || Emergency Ordering- Single Sign-On (SSO) and Role-Based User Experience Customization
    //Added building
    getAccounts(user: string): Observable<any> {
        return this.http.get(this.accountsServiceEndpoint + '/users/' + user + '/accounts?building='+this.authGuard.getBuilding())
            .pipe(map((response) => response));
    }
}
