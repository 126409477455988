import {Injectable} from '@angular/core';
import {Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';
import {AuthorizationService} from '../services/authorization.service';
import {Observable, of} from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {Environment} from '../env/environment';
import {Constants} from '../constants/app.constants';
import {User} from '../model/user';

let OktaAuth = require('@okta/okta-auth-js/dist/okta-auth-js.min.js');

@Injectable()
export class AuthGuard implements CanActivate {
    private authClient: any;
    constructor(private router: Router,
                private authenticationService: AuthenticationService, private authorizationService: AuthorizationService) {
    }
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  
        let currentUser = localStorage.getItem('currentUser');
        let currentIdToken = this.authenticationService.getIdTokenFromManager();
        let currentAccessToken = this.authenticationService.getAccessTokenFromManager();
               
        if (currentIdToken && typeof currentIdToken !== 'undefined' &&
            currentAccessToken && typeof currentAccessToken !== 'undefined' &&
            currentUser && typeof currentUser !== 'undefined') {
            return this.authenticationService.verifyToken().pipe(map(
                data => {
                    let subject = this.authenticationService.getUserFromIDToken();
                    let subjectFromAccessToken = JSON.stringify(data[1]);
                    return JSON.parse(subjectFromAccessToken).sub === subject;
                })).pipe(catchError(err => {
                localStorage.clear();
                this.router.navigateByUrl('login');
                return of(false);
            }));
        } else {
            //Changes done for UPMC SSO OE-3083
            this.makeSessionMeCall();
            
        }
    }
    //Changes done for UPMC SSO OE-3083 starts . This method makes sessions/me OKTA call and get logged user details.  
    makeSessionMeCall(){
        this.authenticationService.makeSessionMeCallForSSO().subscribe(
            data => {
                switch (data.status) {
                    case 'ACTIVE':                       
                        this.makeAuthorizeCall(data.login);
                        break;
                    default:
                        this.router.navigateByUrl('login');
                        break;
                }
                
            },
            error => {
                this.router.navigateByUrl('login');
            }
        );
        

    }

    //This method makes /authorize/<username> call to check whether user is authorized to access EO application or not
    makeAuthorizeCall(username: string) {
        
        this.authorizationService.authorize(username)
        //.retry(2) //Changes done for Angular6 upgrade
        .subscribe(
            userStatus => {
                if (userStatus.user && userStatus.user != null) {
                    switch (userStatus.status) {
                        case Constants.AUTHORIZED:
                            this.setLocalStorage(userStatus.user);
                            this.generateIdToken();
                            break;
                        default:
                            this.router.navigateByUrl('error');
                            break;
                    }
                } else {
                    this.router.navigateByUrl('error');
                }
            },
            error => {
                this.router.navigateByUrl('error');
            });

    }

    //This method makes /authorize call to get IdToken and AccessToken
    generateIdToken() {
        
        this.authenticationService.generateIdTokenForSSO()
            .subscribe(
                data => {
                    if (data.length > 1) {
                    
                        this.authenticationService.addIdTokenToManager(data[0]);
                        this.authenticationService.addAccessTokenToManager(data[1]);
                        this.generateAppToken();
                        } else {
                        this.router.navigateByUrl('login');                    
                    }
                },
                error => {
                    this.router.navigateByUrl('login');
                });
    }

    //This method generates app-token and redirects to home page
    generateAppToken() {
        this.authorizationService.generateAppToken().subscribe(
            data => {
                localStorage.setItem('app-token', data.appToken);
                this.router.navigateByUrl('home');
            },
            error => {
                this.router.navigateByUrl('login');
            });
    }

    setLocalStorage(user: User) {
        localStorage.setItem('currentUser', JSON.stringify(user.userId));
        localStorage.setItem('id', JSON.stringify(user.id));
        localStorage.setItem('numberOfTokenRefreshes', '4');
    }

//Changes done for UPMC SSO OE-3083 ends
	
	//OE-7438 || Publix || Emergency Ordering- Single Sign-On (SSO) and Role-Based User Experience Customization
    public getBuilding() {
     	let building = localStorage.getItem('publix-building');
        return building !== null ? building : '';
    }

}
