import {Component, OnInit, OnDestroy} from '@angular/core';
import {AuthenticationService} from '../services/authentication.service';
import {Subscription} from 'rxjs';
import {TimerObservable} from 'rxjs/observable/TimerObservable';

@Component({
    selector: 'my-alt-ordering-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

    tokenExpiresAt;
    timeTillTokenRefresh: Subscription;

    constructor(private authenticationService: AuthenticationService) {
        let tokenExpirationTime = this.authenticationService.getIdTokenExpiration();
        this.tokenExpiresAt = new Date(tokenExpirationTime * 1000);
    }

    ngOnInit() {
        this.startTimerAndRefreshToken15MinutesBeforeExpiry(this.getTokenExpirationFromNow());
    }

    getTokenExpirationFromNow(): number {
        let date = new Date();
        return (this.tokenExpiresAt.getTime() - date.getTime()) / 1000;
    }

    startTimerAndRefreshToken15MinutesBeforeExpiry(tokenExpiresIn: number) {
        let timer = TimerObservable.create(0, 1000);
        this.timeTillTokenRefresh = timer.subscribe(time => {
            tokenExpiresIn--;
            if (tokenExpiresIn < 900) {
                console.log('OKTA tokens are about to expire. Auto refreshing OKTA tokens...');
                this.refreshTokens();
            }
        });
    }

    refreshTokens() {
        this.timeTillTokenRefresh.unsubscribe();
        let numberOfTokenRefreshesLeft = Number(localStorage.getItem('numberOfTokenRefreshes'));
        if (numberOfTokenRefreshesLeft && numberOfTokenRefreshesLeft > 0) {
            this.authenticationService.refreshTokens().subscribe(
                data => {
                    this.authenticationService.addIdTokenToManager(data.val1);
                    this.authenticationService.addAccessTokenToManager(data.val2);
                    let tokenExpirationTime = this.authenticationService.getIdTokenExpiration();
                    this.tokenExpiresAt = new Date(tokenExpirationTime * 1000);
                    numberOfTokenRefreshesLeft--;
                    localStorage.setItem('numberOfTokenRefreshes', numberOfTokenRefreshesLeft.toString());
                    this.startTimerAndRefreshToken15MinutesBeforeExpiry(this.getTokenExpirationFromNow());
                },
                error => {
                    console.log('Unable to refresh Tokens:::' + error);
                });
        }
    }

    ngOnDestroy() {
        if (this.timeTillTokenRefresh) { this.timeTillTokenRefresh.unsubscribe(); }
    }
}
