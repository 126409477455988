import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Environment } from '../env/environment';
import { Observable, of } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { UserStatus } from '../model/userstatus';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthorizationService {

    private userAuthorizationEndpoint: string;
    private tokenGenerationEndpoint: string;
    private apiKey: string;

    constructor(private http: HttpClient, private environment: Environment, private authenticationService: AuthenticationService) {
        this.userAuthorizationEndpoint = environment.getEnvironmentVariable('authorizationEndPoint');
        this.tokenGenerationEndpoint = environment.getEnvironmentVariable('tokenGenerator');
        this.apiKey = environment.getEnvironmentVariable('apiKey');
    }
    //OE-3083 Modified for Angular6 upgrade 
    authorize(username: string): Observable<any> {
        let options = this.generateOptionsHeaders();
        return this.http.get(this.userAuthorizationEndpoint + '/authorize/' + username, options)
            .pipe(map((response) => response));
    }
    //OE-3083 Modified for Angular6 upgrade
    generateAppToken(): Observable<any> {
        let options = this.generateOptionsHeadersForTokenGenerator();
        return this.http.get(this.tokenGenerationEndpoint + '/token-generator/token', options)
            .pipe(map((response) => response));
    }
    //OE-3083 Modified for Angular6 upgrade
    generateOptionsHeadersForTokenGenerator() {
        let headers = new HttpHeaders({
            'Content-Type' : 'application/json',
            'Accept' : 'application/json',
            'id-token': this.getIdToken(),
            'access-token': this.getAccessToken(),
            'x-api-key' : this.apiKey
        });
            
        let options = {          
                headers: headers
             };
            return options;
        }
    //OE-3083 Modified for Angular6 upgrade
    generateOptionsHeaders() {
        let headers = new HttpHeaders({
            'Content-Type' : 'application/json',
            'Accept' : 'application/json',
            'x-api-key' : this.apiKey
        });
            
        let options = {          
                headers: headers
             };
            return options;
        }

    getIdToken() {
        let idTokenJson = JSON.stringify(this.authenticationService.getIdTokenFromManager());
        return JSON.parse(idTokenJson).idToken;
    }

    getAccessToken() {
        let accessTokenJson = JSON.stringify(this.authenticationService.getAccessTokenFromManager());
        return JSON.parse(accessTokenJson).accessToken;
    }

}
