import {Enum} from './enume';

export class Constants extends Enum<string> {

    public static readonly CURRENT_ACCOUNT = new Enum('current-account');
    public static readonly CURRENT_DC = new Enum('current-dc');
    public static readonly ORDER_ID_NON_C2  = new Enum('Order ID');
    public static readonly PO_REQUIRED = new Enum('po-required');
    public static readonly BY_CIN = new Enum('cin');
    public static readonly BY_NDC = new Enum('ndc');
    public static readonly DELETE_ACTION_TYPE = new Enum('delete');
    public static readonly CLEAR_ACTION_TYPE = new Enum('clear');
    public static readonly CONFIRM_ACTION = new Enum('confirm');
    public static readonly INFORMATIONAL = new Enum('informational');
    public static readonly NONE_ACTION_TYPE = new Enum('none');
    public static readonly FOCUS_SEARCH = new Enum('search');
    public static readonly REDIRECT_TO_OE = new Enum('redirect');
    public static readonly FOCUS_PO = new Enum('po');
    public static readonly ITEM_ID = new Enum('IT');
    public static readonly PO_ID = new Enum('PO');
    public static readonly MO_PO_ID = new Enum('MOPO');
    public static readonly ORDER_EXPRESS = new Enum('OrderExpress');

    public static readonly ALLOWED_SPECIAL_CHAR_CODES_PO_NUMBER = [45, 95, 32, 44];
    public static readonly MOVE_RIGHT_LEFT = [8, 0];
    public static readonly ALLOWED_CHAR_CODES_FOR_COPY_PASTE = [118, 114, 122, 90, 86, 82];

    public static readonly AUTHORIZED = 3;
    public static readonly USER_NOT_AUTHORIZED_BUYER = 2;
    public static readonly USER_NOT_FOUND = 1;
    public static readonly USER_IS_PR = 4;
}
