import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/Subject';
import {Account} from '../model/account';
import {Product} from '../model/product';

@Injectable()
export class ComponentCommunicationService {

    // Observable sources
    private accountChangedSource = new Subject<Account>();
    private productSearchSource = new Subject<Product>();
    private poNumberSource = new Subject<string>();
    private focusBackToSearchSource = new Subject<boolean>();
    private focusBackToPONumberSource = new Subject<boolean>();
    private clearInputsSource = new Subject<boolean>();
    private multipleProductsSource = new Subject<Product[]>();
    private isOEUpSource = new Subject<boolean>();

    // Observable streams
    accountChanged$ = this.accountChangedSource.asObservable();
    productSearch$ = this.productSearchSource.asObservable();
    poNumberField$ = this.poNumberSource.asObservable();
    focusBackToSearch$ = this.focusBackToSearchSource.asObservable();
    focusBackToPONumber$ = this.focusBackToPONumberSource.asObservable();
    clearInputs$ = this.clearInputsSource.asObservable();
    multipleProducts$ = this.multipleProductsSource.asObservable();
    isOEUp$ = this.isOEUpSource.asObservable();

    accountChangedTo(newAccount: Account) {
        this.accountChangedSource.next(newAccount);
    }

    productSearched(product: Product) {
        this.productSearchSource.next(product);
    }

    focusBackToSearch(message: boolean) {
        this.focusBackToSearchSource.next(message);
    }

    focusBackToPO(message: boolean) {
        this.focusBackToPONumberSource.next(message);
    }

    currentPONumber(poNumber: string) {
        this.poNumberSource.next(poNumber);
    }

    clearInputs(clear: boolean) {
        this.clearInputsSource.next(clear);
    }

    multipleProducts(products: Product[]) {
        this.multipleProductsSource.next(products);
    }

    setOEStatus(oeStatus: boolean) {
        this.isOEUpSource.next(oeStatus);
    }

}
