import {Component, OnInit, ViewChild, ElementRef, AfterViewInit, Renderer} from '@angular/core';
import {Router} from '@angular/router';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {AuthenticationService} from '../services/authentication.service';
import {OeStatusService} from '../services/oestatus.service';
import {Constants} from '../constants/app.constants';
import {AuthorizationService} from '../services/authorization.service';
import {User} from '../model/user';
import {RedirectService} from '../services/redirect.service';
import { Environment } from '../env/environment';
import { HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
    selector: 'my-alt-ordering-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {

    @ViewChild('usernameInput') input: ElementRef;
    loginForm: FormGroup;
    currentlyLogging: boolean;
    authErrorMessage1: string;
    authErrorMessage2: string;


    constructor(private formBuilder: FormBuilder,
        private router: Router,
        private authenticationService: AuthenticationService,
        private renderer: Renderer,
        private oeStatusService: OeStatusService,
        private authorizationService: AuthorizationService,
        private redirectService: RedirectService,
        private environment: Environment
    ) { }

    ngOnInit() {
        this.authenticationService.logout().subscribe(
            data => {
                this.authenticationService.clearTokens();
            }, error => {
                this.authenticationService.clearTokens();
            }
        );
        this.buildLoginForm();
    }

    buildLoginForm(): void {
        this.loginForm = this.formBuilder.group({
            'username': ['', Validators.required],
            'password': ['', Validators.required]
        });
        this.loginForm.valueChanges
            .subscribe(data => { this.onValueChanged(data); });
        this.onValueChanged();
    }

    onValueChanged(data?: any) {
        this.authErrorMessage1 = '';
        this.authErrorMessage2 = '';
    }

    ngAfterViewInit() {
        this.renderer.invokeElementMethod(this.input.nativeElement,
            'focus');
    }

    login() {
        this.currentlyLogging = true;
        this.oeStatusService.getAppStatus(Constants.ORDER_EXPRESS.value).subscribe(
            response => {
                if (response.oeStatus.status) {
                    this.redirect();
                } else {
                    this.authorizationService.authorize(this.getUserName())
                        //.retry(2) //Changes done for Angular6 upgrade
                        .subscribe(
                            userStatus => {
                                if (userStatus.user && userStatus.user != null) {
                                    switch (userStatus.status) {
                                        case Constants.AUTHORIZED:
                                            this.authenticate(userStatus.user);
                                            break;
                                        case Constants.USER_IS_PR:
                                            this.authErrorMessage1 = 'We apologize for the inconvenience, ' +
                                                'however Emergency Ordering is not available to customers in Puerto Rico. ' +
                                                'Please call Customer Service at 787-625-4200 to place your order.';
                                            this.authErrorMessage2 = '';
                                            this.currentlyLogging = false;
                                            break;
                                        case Constants.USER_NOT_AUTHORIZED_BUYER:
                                            this.authErrorMessage2 = '';
                                            this.authErrorMessage1 = 'This user id does not have access to Order. ' +
                                                'Please login using a different user id.';
                                            this.currentlyLogging = false;
                                            break;
                                        default:
                                            this.authErrorMessage1 = 'Error during Authorization';
                                            this.authErrorMessage2 = '';
                                            this.currentlyLogging = false;
                                            break;
                                    }
                                } else {
                                    this.authErrorMessage1 = 'Invalid username and/or password, please try again.';
                                    this.authErrorMessage2 = 'Password is case sensitive';
                                    this.currentlyLogging = false;
                                }
                            },
                            error => {
                                this.authErrorMessage2 = '';
                                this.authErrorMessage1 = 'Unable to Authorize.';
                                this.currentlyLogging = false;
                            });
                }
            });
    }

    authenticate(user: User) {
        this.authenticationService.login(this.getUserName(), this.getPassword())
            .subscribe(
                data => {
                    switch (data.status) {
                        case 'SUCCESS':
                            this.generateIdToken(data.sessionToken, user);
                            break;
                        default:
                            this.authErrorMessage1 = 'Unable to Authenticate.';
                            this.currentlyLogging = false;
                            break;
                    }
                },
                error => {
                    this.authErrorMessage1 = 'Invalid username and/or password, please try again.';
                    this.authErrorMessage2 = 'Password is case sensitive';
                    this.currentlyLogging = false;
                });
    }

    generateIdToken(sessionToken: string, authenticatedUser: User) {
        this.authenticationService.generateIdToken(sessionToken)
            .subscribe(
                data => {
                    if (data.length > 1) {
                        this.authenticationService.addIdTokenToManager(data[0]);
                        this.authenticationService.addAccessTokenToManager(data[1]);
                        this.setLocalStorage(authenticatedUser);
                        this.generateAppToken();
                    } else {
                        this.authErrorMessage1 = 'Unable to Authorize.';
                        this.currentlyLogging = false;
                    }
                },
                error => {
                    this.authErrorMessage1 = 'Unable to Authorize.';
                    this.currentlyLogging = false;
                });
    }

    generateAppToken() {
        this.authorizationService.generateAppToken().subscribe(
            data => {
                localStorage.setItem('app-token', data.appToken);
                this.router.navigateByUrl('home');
            },
            error => {
                this.authErrorMessage1 = 'Unable to Authorize.';
                this.currentlyLogging = false;
            });
    }

    setLocalStorage(user: User) {
        localStorage.setItem('currentUser', JSON.stringify(user.userId));
        localStorage.setItem('id', JSON.stringify(user.id));
        localStorage.setItem('numberOfTokenRefreshes', '4');
    }

    redirect() {
        this.redirectService.redirectToOE();
    }

    public getUserName(): string {
        return this.loginForm.controls['username'].value;
    }

    public getPassword(): string {
        return this.loginForm.controls['password'].value;
    }

}
