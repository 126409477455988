import {Component, OnInit, OnDestroy} from '@angular/core';


@Component({
    selector: 'my-alt-ordering-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit, OnDestroy {

    

    constructor() {
        
    }

    ngOnInit() {
       
    }

    ngOnDestroy() {
        
    }
}
