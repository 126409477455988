import {Injectable} from '@angular/core';
import {Response} from '@angular/http';
import {Observable} from 'rxjs';
import {Product} from '../model/product';
import {Environment} from '../env/environment';
import {CustomHttpService} from './customhttp.service';
import { map } from 'rxjs/operators';

@Injectable()
export class ProductsService {

    host: string;

    constructor(private http: CustomHttpService, private environment: Environment) {
        this.host = environment.getEnvironmentVariable('productsServiceEndpoint');
    }
    //OE-3083 Modified for Angular6 upgrade
    getProductByCIN(cin: number): Observable<any> {
        return this.http.get(this.host + '/products/cin/' + cin).pipe(map((response) => response));
    }
    //OE-3083 Modified for Angular6 upgrade
    getProductByNDCorUPC(ndcOrUpc: string): Observable<any> {
        return this.http.get(this.host + '/products/ndc-upc/' + ndcOrUpc).pipe(map((response) => response));
    }
}
