import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {TimerObservable} from 'rxjs/observable/TimerObservable';
import {OeStatusService} from '../services/oestatus.service';
import {Constants} from '../constants/app.constants';
import {OEStatusWithRemainingTime} from '../model/oestatuswithremainingtime';
import {Router} from '@angular/router';
import {RedirectService} from '../services/redirect.service';
import {ComponentCommunicationService} from '../services/componentcommunication.service';
import { HttpParams } from '@angular/common/http';
import { AccountsService } from '../services/accounts.service';
import { Environment } from '../env/environment';

@Component({
    selector: 'my-alt-ordering-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnDestroy, OnInit {

    displayTime: string = '';
    oeStatusFinderSubscription: Subscription;
    countDownTimerSubscription: Subscription;

    constructor(private router: Router,
        private oeStatusService: OeStatusService,
        private redirectService: RedirectService,
        private componentCommService: ComponentCommunicationService, private accountsService: AccountsService, private environment: Environment) { }

    ngOnInit() {
        this.checkAndStartTimerIfOrderExpressIsUp();
    }

    checkAndStartTimerIfOrderExpressIsUp() {
        let timer = TimerObservable.create(0, 60000);
        this.oeStatusFinderSubscription = timer.subscribe(
            time => {
                this.oeStatusService.getAppStatus(Constants.ORDER_EXPRESS.value).subscribe(
                    (data: OEStatusWithRemainingTime) => {
                        this.componentCommService.setOEStatus(data.oeStatus.status);
                        this.startCountDownTimerIfOEIsUp(data);
                    },
                    error => {
                        /* TODO: Figure out what to do when oestatus service errors */
                        // this.router.navigateByUrl('login');
                    });
            });
    }

    startCountDownTimerIfOEIsUp(data: OEStatusWithRemainingTime) {
        this.countDownTimerUnsubscribe();
        if (data.oeStatus.status) {
            if (data.timeRemaining > 0) {
                let timeInSeconds = data.timeRemaining / 1000;
                let countDownTimer = TimerObservable.create(0, 1000);
                this.countDownTimerSubscription = countDownTimer.subscribe(timer => {
                    timeInSeconds --;
                    if (timeInSeconds > 0) {
                        this.displayTime = this.getSecondsAsDigitalClock(timeInSeconds);
                    } else {
                        this.router.navigateByUrl('login');
                    }
                });
            } else {
                this.displayTime = '';
                this.router.navigateByUrl('login');
            }
        } else {
            this.displayTime = '';
        }
    }

    countDownTimerUnsubscribe() {
        if (this.countDownTimerSubscription) {
            this.countDownTimerSubscription.unsubscribe();
        }
    }

    getSecondsAsDigitalClock(inputSeconds: number) {
        let sec_num = parseInt(inputSeconds.toString(), 10);
        let hours = Math.floor(sec_num / 3600);
        let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        let seconds = sec_num - (hours * 3600) - (minutes * 60);
        let hoursString = (hours < 10) ? '0' + hours + 'h' : hours.toString() + 'h';
        let minutesString = (minutes < 10) ? '0' + minutes + 'm' : minutes.toString() + 'm';
        let secondsString = (seconds < 10) ? '0' + seconds + 's' : seconds.toString() + 's';
        return hoursString + ':' + minutesString + ':' + secondsString;
    }

    public getUser() {
        return JSON.parse(localStorage.getItem('currentUser'));
    }

    oeStatusFinderUnsubscribe() {
        if (this.oeStatusFinderSubscription) {
            this.oeStatusFinderSubscription.unsubscribe();
        }
    }

    logoutAndRedirect() {
        this.router.navigateByUrl('login');
        this.redirectService.redirectToOE();
    }

    ngOnDestroy() {
        this.oeStatusFinderUnsubscribe();
        this.countDownTimerUnsubscribe();
    }
}
