export class ModalType {
    type: string;
    message: string;
    action: string;
    cancelAction: string;
    bigModal: boolean;

    constructor(type?: string, message?: string, action?: string, cancelAction?: string, bigModal?: boolean) {
        this.type = type;
        this.message = message;
        this.action = action;
        this.cancelAction = cancelAction;
        this.bigModal = bigModal;
    }
}
