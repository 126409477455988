import {Injectable} from '@angular/core';
import {Response} from '@angular/http';
import {Observable} from 'rxjs';
import {Environment} from '../env/environment';
import {OrderProduct} from '../model/orderproduct';
import {CustomHttpService} from './customhttp.service';
import { map } from 'rxjs/operators';
import { AuthGuard } from '../guards';

@Injectable()
export class OrdersService {

    host: string;

    constructor(private http: CustomHttpService, private environment: Environment, private authGuard: AuthGuard) {
        this.host = environment.getEnvironmentVariable('orderServiceEndPoint');
    }
    //OE-3083 Modified for Angular6 upgrade
    createOrder(products: OrderProduct[], dc: number, account: number, poNumber: string): Observable<any> {
		
        return this.http.post(this.host + this.encodedURI(dc, account, poNumber), products)
            .pipe(map((response) => response));
    }

    encodedURI(dc: number, account: number, poNumber: string) {
		//OE-7439 || Publix || Emergency Ordering - Autogenerate Purchase Order Number
		//added building in URI
        return encodeURI('/dc/' + dc + '/account/' + account + '/orders?poNumber=' + poNumber + '&building=' + this.authGuard.getBuilding());
    }
}
