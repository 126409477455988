import {Injectable} from '@angular/core';
import {CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Router} from '@angular/router';
import {OeStatusService} from '../services/oestatus.service';
import {Constants} from '../constants/app.constants';
import {Observable} from 'rxjs';
import {RedirectService} from '../services/redirect.service';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators'
import { Environment } from '../env/environment';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class OeStatusGuard implements CanActivate {

    constructor(private oeStatusService: OeStatusService,
                private redirectService: RedirectService,
		private environment: Environment,
                private router: Router) { }

    public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.oeStatusService.getAppStatus(Constants.ORDER_EXPRESS.value).pipe(map(data => {
            if (data.oeStatus.status) {
                localStorage.clear();
                this.redirect();
                return false;
            } else {
                return true;
            }
        })).pipe(catchError(() => {
            return of(true);
        }));
    }

    redirect() {
        this.redirectService.redirectToOE();
    }
}
