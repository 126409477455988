import {Component, OnDestroy, ViewChild, ElementRef} from '@angular/core';
import {ComponentCommunicationService} from '../services/componentcommunication.service';
import {Product} from '../model/product';
import {Subscription} from 'rxjs';
import {ModalCommunicationService} from '../services/modalcommunication.service';
import {ModalType} from '../model/modaltype';
import {Constants} from '../constants/app.constants';
import {ModalMessageConstants} from '../constants/modal.constants';
import {OrderProduct} from '../model/orderproduct';
import {Account} from '../model/account';
import {OrdersService} from '../services/orders.service';
import {AppErrorConstants} from '../constants/apperror.constants';
import {Router} from '@angular/router';

@Component({
    selector: 'my-alt-ordering-order-list',
    templateUrl: './orderlist.component.html',
    styleUrls: ['./orderlist.component.scss', './ordertable.component.scss']
})

export class OrderListComponent implements OnDestroy {

    @ViewChild('headerSelected') headerSelected: ElementRef;

    products: Product[] = [];
    componentSubscription: Subscription;
    productSearchComponentSubscription: Subscription;
    modalSubscription: Subscription;
    currentAccount: Account;
    currentPONumber: string;
    submitting: boolean;
    isOeUp: boolean;
    maxProductMessage: string = '';


    constructor(private communicationService: ComponentCommunicationService,
                private modalService: ModalCommunicationService,
                private ordersService: OrdersService,
                private router: Router) {
        this.captureProductSearchOutput();
        this.takeAction();
        this.getCurrentAccount();
        this.getCurrentPONumber();
        this.multipleProductsAdded();
        this.captureOeStatus();
        this.maxProductMessage = '';
    }

    captureProductSearchOutput() {
        this.productSearchComponentSubscription = this.communicationService.productSearch$.subscribe(
            product => {
                this.addProductAtTheTop(product);
            });
    }

    takeAction() {
        this.modalSubscription = this.modalService.action$.subscribe(
            action => {
                switch (action) {
                    case Constants.DELETE_ACTION_TYPE.value:
                        this.deleteSelectedOrderLines();
                        this.generateEvents();
                        this.setMaximumallowedMessage('');
                        break;
                    case Constants.CLEAR_ACTION_TYPE.value:
                        this.removeAllProducts();
                        this.generateEvents();
                        this.setMaximumallowedMessage('');
                        break;
                    case Constants.FOCUS_PO.value:
                        this.communicationService.focusBackToPO(true);
                        this.setMaximumallowedMessage('');
                        break;
                    case Constants.FOCUS_SEARCH.value:
                        this.generateEvents();
                        this.setMaximumallowedMessage('');
                        break;
                    case Constants.REDIRECT_TO_OE.value:
                        this.setMaximumallowedMessage('');
                        this.router.navigateByUrl('login');
                        break;
                    default:
                        console.log('Nothing to do.');
                }
            });
    }

    generateEvents() {
        this.communicationService.focusBackToSearch(true);
    }

    getCurrentAccount() {
        this.componentSubscription = this.communicationService.accountChanged$.subscribe(
            account => {
                this.currentAccount = account;
            });
    }

    getCurrentPONumber() {
        this.componentSubscription = this.communicationService.poNumberField$.subscribe(
            poNumber => {
                this.currentPONumber = poNumber;
            });
    }

    captureOeStatus() {
        this.componentSubscription = this.communicationService.isOEUp$.subscribe(
            oeStatus => {
                this.isOeUp = oeStatus;
            });
    }

    multipleProductsAdded() {
        this.componentSubscription = this.communicationService.multipleProducts$.subscribe(
            products => {
                this.processProducts(products);
            });
    }

    processProducts(products: Product[]) {
        let originalProductList = JSON.parse(JSON.stringify(this.products));
        this.setMaximumallowedMessage('');
        let completeProductList: Product[] = [];
        for (let product of products) {
            if (product.description === AppErrorConstants.INVALID_PRODUCT.value ||
                product.description === AppErrorConstants.C2_PRODUCT.value ||
                product.description === AppErrorConstants.UNKNOWN_ITEM.value) {
                product.invalid = true;
            }
            completeProductList.push(this.addProduct(product));
        }
        if (this.getValidProductsSize(this.products) + this.getValidProductsSize(completeProductList)
            <= this.getMaxProductsAllowed() ) {
            this.products = this.products.concat(completeProductList);
        } else {
            this.products = originalProductList;
            this.setMaximumallowedMessage('Import failed: Order imports cannot be greater than 350 products. ' +
                'Please reduce the number of products and try importing again.');
        }
        originalProductList = null;
    }

    setMaximumallowedMessage(message: string) {
        this.maxProductMessage = message;
    }

    onKeyPressOrderLineQty(keyCode, current) {
        if (keyCode && (keyCode >= 48 && keyCode <= 57)) {
            return !(current && current.length === 3 || (current === '' && keyCode === 48));
        }
        return false;
    }

    addProduct(product: Product): Product {
        let matchingProducts = this.getMatchingProducts(this.products, product);
        if (matchingProducts.length === 0) {
            return product;
        } else {
            return this.getProductWithNewQty(matchingProducts, product);
        }
    }

    addProductAtTheTop(product: Product) {
        this.setMaximumallowedMessage('');
        if (this.products.length > 0) {
            let matchingProducts = this.getMatchingProducts(this.products, product);
            if (matchingProducts.length === 0) {
                if ((this.getValidProductsSize(this.products) + 1) > this.getMaxProductsAllowed()) {
                    this.setMaximumallowedMessage('You have exceeded the 350 maximum products allowed. ' +
                        'Please delete a product and try again.');
                } else {
                    this.products.splice(0, 0, product);
                }
            } else {
                this.products.splice(0, 0, this.getProductWithNewQty(matchingProducts, product));
            }
        } else {
            this.products.push(product);
        }
    }

    getValidProductsSize(products: Product[]): number {
        let validProducts = products.filter(product => !product.invalid);
        return validProducts.length;
    }

    getProductWithNewQty(matchingProducts: Product[], incomingProduct: Product): Product {
        let newProductQty = this.setNewQuantityForExisting(matchingProducts, incomingProduct.quantity);
        let deletedProduct: Product[] = this.removeDuplicates(incomingProduct);
        deletedProduct[0].quantity = newProductQty;
        return deletedProduct[0];
    }

    getMatchingProducts(currentProducts: Product[], incomingProduct: Product) {
        return currentProducts.filter(
            existing =>
                (incomingProduct.cin != null ? existing.cin === incomingProduct.cin : existing.ndc === incomingProduct.ndc));
    }

    removeDuplicates(product: Product) {
        let productsListLength = this.products.length;
        let deletedProduct;
        while (productsListLength--) {
            if (product.cin !== null) {
                if (this.products[productsListLength].cin === product.cin) {
                    deletedProduct = this.products.splice(productsListLength, 1);
                }
            } else {
                if (this.products[productsListLength].ndc === product.ndc) {
                    deletedProduct = this.products.splice(productsListLength, 1);
                }
            }
        }
        return deletedProduct;
    }

    setNewQuantityForExisting(products: Product[], quantity: number): number {
        for (let product of products) {
            quantity = Number(quantity) + Number(product.quantity);
        }
        return quantity;
    }

    selectHeader() {
        this.selectAll(this.headerSelected.nativeElement.checked);
    }

    selectAll(value: boolean) {
        for (let product of this.products) {
            product.selected = value;
        }
    }

    isSelectAll(): boolean {
        return this.headerSelected.nativeElement.checked;
    }

    updateSelectAll() {
        this.headerSelected.nativeElement.checked =
            this.products.filter(product => product.selected === true).length === this.products.length;
    }

    disableDeleteButton() {
        return this.products.filter(product => product.selected === true).length === 0;
    }

    showChildModalDelete() {
        this.modalService.showModal(this.buildDeleteModalType());
    }

    deleteSelectedOrderLines() {
        let productsLength = this.products.length;
        if (!this.isSelectAll()) {
            while (productsLength--) {
                if (this.products[productsLength].selected) {
                    this.products.splice(productsLength, 1);
                }
            }
        } else {
            this.removeAllProducts();
        }
    }

    removeAllProducts() {
        this.products.splice(0, this.products.length);
    }

    showChildModalClear() {
        this.modalService.showModal(this.buildClearModalType());
    }

    removeInvalidAndSubmit(products: Product[]) {
        this.submitting = true;
        let orderingProducts = this.removeInvalidProducts(products);
        if (orderingProducts.length > 0) {
            this.submitOrder(orderingProducts);
        } else {
            this.submitting = false;
            this.modalService.showModal(this.buildAllItemInvalidModalType());
        }
    }

    removeInvalidProducts(products: Product[]): Product[] {
        return products.filter(product => !product.invalid);
    }

    submitOrder(products: Product[]) {
		//OE-7439 || Publix || Emergency Ordering - Autogenerate Purchase Order Number
		//Added isPublixCustomer check to bypass po num for Force PO Accounts
		let isPublixCustomer = localStorage.getItem('publix-building') === null ? false : true;
				
        let isPORequired = this.currentAccount.poRequired === 'Y';
        this.currentPONumber = typeof this.currentPONumber === 'undefined' ?  '' : this.currentPONumber;
        if (isPORequired && this.isEmpty(this.currentPONumber) && !isPublixCustomer) {
            this.submitting = false;
            this.modalService.showModal(this.buildPORequiredModalType());
            this.communicationService.focusBackToPO(true);
        } else {
            let productsWithOrderAttribs = this.removeUnNeededAttribs(products);
            this.ordersService.createOrder(productsWithOrderAttribs,
                this.currentAccount.dc, this.currentAccount.account, this.currentPONumber)
                //.retry(2)
                .subscribe((data: any) => {
                        this.submitting = false;
                        this.createOrderAcknowldegementModal(data);
                    },
                    error => {
                        this.submitting = false;
                        this.modalService.showModal(this.buildOrderSubmissionErrorModal());
                    },
                    () => {
                        this.products.splice(0, this.products.length);
                        this.communicationService.clearInputs(true);
                    });
        }
    }

    createOrderAcknowldegementModal(data) {
        this.modalService.showModal(this.buildOrderAcknowledgementModal(data));
    }

    removeUnNeededAttribs(products: Product[]) {
        let orderingProducts = [];
        for (let product of products) {
            let orderingProduct = new OrderProduct();
            orderingProduct.cin = product.cin;
            orderingProduct.quantity = product.quantity;
            orderingProduct.schedule = product.deaSchedule.deaScheduleNum;
            orderingProducts.push(orderingProduct);
        }
        return orderingProducts;
    }

    buildClearModalType(): ModalType {
        return new ModalType(Constants.CONFIRM_ACTION.value,
            ModalMessageConstants.CLEAR_MODAL_MESSAGE.value,
            Constants.CLEAR_ACTION_TYPE.value,
            Constants.FOCUS_SEARCH.value);
    }

    buildDeleteModalType(): ModalType {
        return new ModalType(Constants.CONFIRM_ACTION.value,
            ModalMessageConstants.DELETE_MODAL_MESSAGE.value,
            Constants.DELETE_ACTION_TYPE.value,
            Constants.FOCUS_SEARCH.value);
    }

    buildPORequiredModalType() {
        return new ModalType(Constants.INFORMATIONAL.value,
            ModalMessageConstants.PO_REQUIRED_MESSAGE.value,
            Constants.NONE_ACTION_TYPE.value,
            Constants.FOCUS_PO.value);
    }

    buildOrderAcknowledgementModal(data) {
        let confirmationMessage = ModalMessageConstants.ORDER_SUBMISSION_SUCCESS.value +
            Constants.ORDER_ID_NON_C2.value + ': ' + data.orderIdNonC2;
        return new ModalType(Constants.INFORMATIONAL.value,
            confirmationMessage,
            Constants.NONE_ACTION_TYPE.value,
            this.isOeUp ? Constants.REDIRECT_TO_OE.value : Constants.FOCUS_SEARCH.value,
            true);
    }

    buildOrderSubmissionErrorModal() {
        return new ModalType(Constants.INFORMATIONAL.value,
            ModalMessageConstants.ORDER_SUBMISSION_ERROR.value,
            Constants.NONE_ACTION_TYPE.value,
            Constants.FOCUS_SEARCH.value);
    }

    buildAllItemInvalidModalType() {
        return new ModalType(Constants.INFORMATIONAL.value,
            ModalMessageConstants.ALL_ITEMS_INVALID.value,
            Constants.NONE_ACTION_TYPE.value,
            Constants.FOCUS_SEARCH.value);
    }

    isEmpty(stringToCheck: string): boolean {
        return !stringToCheck || stringToCheck === null || stringToCheck.trim() === '';
    }

    getMaxProductsAllowed(): number {
        return 350;
    }

    ngOnDestroy() {
        this.productSearchComponentSubscription.unsubscribe();
        this.componentSubscription.unsubscribe();
        this.modalSubscription.unsubscribe();
    }

}
