import {Component, OnInit} from '@angular/core';
import { AccountsService } from '../services/accounts.service';
import { Account } from '../model/account';
import {Constants} from '../constants/app.constants';
import {ComponentCommunicationService} from '../services/componentcommunication.service';

@Component({
    selector: 'my-alt-ordering-accounts',
    templateUrl: './accounts.component.html',
    styleUrls: ['./accounts.component.scss']
})

export class AccountsComponent implements OnInit {

    accounts: Account[];
    defaultAccount: string;
    currentSelectedAccount: number;
    errorGettingAccounts: string;
    dropDownInVisible: boolean;
    query: string;
    searching: boolean;
    filteredAccountList: any[];
    fetchingAccounts: boolean;

    constructor(private accountsService: AccountsService, private communicationService: ComponentCommunicationService) {}

    ngOnInit() {
        this.getAccounts();
        this.dropDownInVisible = true;
        this.query = '';
        this.searching = false;
        this.filteredAccountList = [];
    }

    changeAccounts(account: any) {
        if (!(this.currentSelectedAccount === account.id)) {
            this.defaultAccount = this.displayAccount(account);
        }
    }

    showHideDropDown() {
        this.dropDownInVisible = !this.dropDownInVisible;
    }

    close() {
        if (!this.dropDownInVisible) {
            this.dropDownInVisible = !this.dropDownInVisible;
        }
    }

    startSearch() {
        this.searching = !this.searching;
    }

    closeAccountSearch() {
        this.searching = false;
        this.query = '';
        this.filteredAccountList = [];
        this.dropDownInVisible = true;
    }

    filterAccounts() {
        if (this.query !== '') {
            this.filteredAccountList = this.accounts.filter(function(account) {
                if (account.accountAlias !== '') {
                    return account.accountAlias.toLowerCase().indexOf(this.query.toLowerCase()) > -1;
                } else {
                    return account.accountName.toLowerCase().indexOf(this.query.toLowerCase()) > -1;
                }
            }.bind(this));
        } else {
            this.filteredAccountList = [];
        }
    }

    selectAccount(newlySelectedAccount) {
        this.filteredAccountList = [];
        this.searching = !this.searching;
        this.query = '';
        this.changeAccounts(newlySelectedAccount);
    }

    private getAccounts() {
        this.fetchingAccounts = true;
        this.accountsService.getAccounts(this.getUser())
            //.retry(2)//OE-3083 Modified for Angular6 upgrade
            .subscribe(
                data => {
                    console.log("Inside data of getAccounts");
                    console.log(data);
                    this.fetchingAccounts = false;
                    this.defaultAccount = this.displayAccount(data[0]);
                    this.accounts = data;
                },
                error => {
                    this.errorGettingAccounts = error;
                    this.fetchingAccounts = false;
                });
    }

    private displayAccount(accountData: Account) {
        this.populateLocalStorage(accountData);
        this.currentSelectedAccount = accountData.id;
        this.communicationService.accountChangedTo(accountData);
        return accountData.accountAlias && accountData.accountAlias !== '' ? accountData.accountAlias : accountData.accountName;
    }

    private populateLocalStorage(accountData: Account) {
        localStorage.setItem(Constants.CURRENT_DC.value, JSON.stringify(accountData.dc));
        localStorage.setItem(Constants.CURRENT_ACCOUNT.value, JSON.stringify(accountData.account));
        localStorage.setItem(Constants.PO_REQUIRED.value, JSON.stringify(accountData.poRequired));
    }

    public getUser() {
        return JSON.parse(localStorage.getItem('currentUser'));
    }
}
