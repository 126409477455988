export class Product {
    cin: number;
    ndc: string;
    description: string;
    quantity: number;
    upc: string;
    invalid: boolean;
    selected: boolean;
    deaSchedule: any;


    constructor(cin?: number, ndc?: string, description?: string, quantity?: number, upc?: string,
                invalid?: boolean, selected?: boolean, deaSchedule?: any) {
        this.cin = cin;
        this.ndc = ndc;
        this.description = description;
        this.quantity = quantity;
        this.upc = upc;
        this.invalid = invalid;
        this.selected = selected;
        this.deaSchedule = deaSchedule;
    }
}
